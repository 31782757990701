import {
    resourceGoals,
    resourceIndustries,
    resourceTags,
    spotonProducts,
  } from "../../constants";
  
  export default {
    pageTitle:
      "SpotOn Case Study | La Tia Juana",
    pageDescription:
      "Serving more tacos and spending less time on payroll at La Tia Juana.",
    title: 'Lunch Rush Made Error Free',
    subTitle: `A busy taquería found a restaurant POS to help make high-volume shifts run smoother in both the front- and back-of-house.`,
    businessName: "La Tia Juana",
    industry: "Restaurant, casual dining",
    location: "Portland, OR",
    products: [
      spotonProducts.RESTAURANT,
      spotonProducts.ORDER,
      spotonProducts.LOYALTY,
      spotonProducts.GUSTO_PAYROLL_INTEGRATION,
    ],
    description:
      "When Christopher Vargas switched from running an auto shop to opening a restaurant, he didn’t anticipate how many competing demands he’d have to juggle. La Tia Juana’s authentic cuisine quickly attracted a loyal following, requiring Vargas to take a second look at kitchen operations and the ordering process.",
    quoteData: {
      imgName: "la-tia-juana.png",
      quote: `“SpotOn’s customer support is the best I’ve ever experienced. I can get on the phone, reach whatever department I need, then I talk to somebody live in less than two minutes.”`,
      personName: "Christopher Vargas",
      personTitle: "Owner, La Tia Juana",
    },
    goal: `Vargas needed a POS system built specifically for restaurants with time-saving integrations to speed up ordering, payroll, and minimize chaos in the kitchen. He needed to take orders quickly and allow the kitchen to work on multiple tickets at a time.`,
    solution: `Now La Tia Juana’s guests can order via QR code or at the counter, where orders get to the kitchen faster thanks to a streamlined user-interface. With the KDS integration, cooks no longer lose track of tickets and can communicate more clearly. SpotOn Restaurant’s payroll integration means employees can clock in directly on the POS and Vargas no longer needs to pay for a separate payroll provider.`,
    solutionReadMore: {
      text: `Read their story.`,
      url: `https://spoton.com/blog/tia-juana-use-and-refer-spoton/`,
    },
    results: {
      title: `The results`,
      stats: [
        { title: `3-4 minutes`, description: `Faster order time` },
        {
          title: `7 hours`,
          description: `Saved per week on payroll`,
        },
        { title: `$600`, description: `Saved per year on payroll fees` },
      ],
    },
  };
  
  export const relatedSuccessStories = [
    {
        title: "A classic cantina stays current",
        imageName: "rancho-rustico.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-10-20",
        href: "/case-studies/rancho-rustico",
        goals: [
          resourceGoals.REACH_NEW_CUSTOMERS,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Opening a barbecue joint with smart tech",
        imageName: "barbwire.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-06-29",
        href: "/case-studies/barbwire-barbecue",
        goals: [
          resourceGoals.CONNECT_AND_SELL_ONLINE,
          resourceGoals.BUILD_CUSTOMER_LOYALTY,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
    {
        title: "Hot & ready without the hassle",
        imageName: "callies-hot-little-biscuit.png",
        industry: resourceIndustries.RESTAURANTS,
        tag: resourceTags.CASE_STUDY,
        date: "2022-10-05",
        href: "/case-studies/callies-hot-little-biscuit",
        goals: [
          resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
          resourceGoals.STREAMLINE_OPERATIONS,
          resourceGoals.GET_SMARTER_DATA,
        ],
    },
  ];
  